import { Box, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

const ListWrapper = styled(Box)(
  ({ theme }) => `
    .MuiTouchRipple-root {
      display: none;
    }

    .MuiListItem-root {
      transition: ${theme.transitions.create(['color', 'fill'])};

      &.MuiListItem-indicators {
        padding: ${theme.spacing(1, 2)};

        .MuiListItemText-root {
          .MuiTypography-root {
            &:before {
              height: 4px;
              width: 22px;
              opacity: 1;
              visibility: hidden;
              display: block;
              position: absolute;
              bottom: -10px;
              transition: all .2s;
              border-radius: ${theme.general.borderRadiusLg};
              content: "";
              background: ${theme.colors.primary.main};
            }
          }
        }

        &.active,
        &:active,
        &:hover {

          background: transparent;

          .MuiListItemText-root {
            .MuiTypography-root {
              &:before {
                opacity: 1;
                visibility: visible;
                bottom: 0px;
              }
            }
          }
        }
      }
    }
  `
);

function HeaderMenu() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
        <ListItem
        classes={{ root: 'MuiListItem-indicators' }}
        button
        component="a" // Change the component to "a" for external links
        href="https://code.deepbrainz.com/" // Replace with the actual Code subdomain link
        target="_blank" // Open link in a new tab
        rel="noopener noreferrer" // Recommended security attribute for external links
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary="Code"
        />
      </ListItem>
      <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component="a" // Change the component to "a" for external links
            href="https://copilot.deepbrainz.com/" // Replace with the actual Copilot subdomain link
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer" // Recommended security attribute for external links
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Copilot"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component="a" // Change the component to "a" for external links
            href="https://studio.deepbrainz.com/" // Replace with the actual Studio subdomain link
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer" // Recommended security attribute for external links
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Studio"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component="a" // Change the component to "a" for external links
            href="https://chat.deepbrainz.com/" // Replace with the actual Chat subdomain link
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer" // Recommended security attribute for external links
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Chat"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component="a" // Change the component to "a" for external links
            href="https://assistant.deepbrainz.com/" // Replace with the actual Assistant subdomain link
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer" // Recommended security attribute for external links
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Assistant"
            />
          </ListItem>
       </List>
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;