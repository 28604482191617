import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Hero = Loader(lazy(() => import('src/content/home')));

const Dashboard = Loader(lazy(() => import('src/content/appsuite/Dashboard')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />, // Default layout for landing page
    children: [
      {
        path: '/',
        element: <Hero /> // Landing page component
      }
    ]
  },
  {
    path: 'appsuite',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      // ...
    ]
  },
];

export default routes;
