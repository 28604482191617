import { useContext } from 'react';
import { ListSubheader, alpha, Box, List, styled, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: ${theme.spacing(1)};

      & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      }
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
  `
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }

          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }
      }
    }
  `
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                GenAI AppSuite: AI Dev Tools
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
              <ListItem component="div">
              <Button
                disableRipple
                component="a" // Change the component to "a" for external links
                href="https://code.deepbrainz.com/" // Replace with the actual Code subdomain link
                startIcon={<BrightnessLowTwoToneIcon />}
                target="_blank" // Open link in a new tab
                rel="noopener noreferrer" // Recommended security attribute for external links
              >
                Code
              </Button>
            </ListItem>
            <ListItem component="div">
                  <Button
                    disableRipple
                    component="a" // Change the component to "a" for external links
                    href="https://copilot.deepbrainz.com/" // Replace with the actual Copilot subdomain link
                    startIcon={<BrightnessLowTwoToneIcon />}
                    target="_blank" // Open link in a new tab
                    rel="noopener noreferrer" // Recommended security attribute for external links
                  >
                    Copilot
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component="a" // Change the component to "a" for external links
                    href="https://studio.deepbrainz.com/" // Replace with the actual Studio subdomain link
                    startIcon={<MmsTwoToneIcon />}
                    target="_blank" // Open link in a new tab
                    rel="noopener noreferrer" // Recommended security attribute for external links
                  >
                    Studio
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component="a" // Change the component to "a" for external links
                    href="https://chat.deepbrainz.com/" // Replace with the actual Chat subdomain link
                    startIcon={<TableChartTwoToneIcon />}
                    target="_blank" // Open link in a new tab
                    rel="noopener noreferrer" // Recommended security attribute for external links
                  >
                    Chat
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component="a" // Change the component to "a" for external links
                    href="https://assistant.deepbrainz.com/" // Replace with the actual Assistant subdomain link
                    startIcon={<TableChartTwoToneIcon />}
                    target="_blank" // Open link in a new tab
                    rel="noopener noreferrer" // Recommended security attribute for external links
                  >
                    Assistant
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        </List>
      </MenuWrapper>

    </>
  );
}

export default SidebarMenu;
